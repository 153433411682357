export const appConfig: {
  [key: string]: string;
} = {
  nodeEnv: process.env.NODE_ENV || 'development',
  releaseVersion:
    import.meta.env.VITE_APP_VERSION || 'v2.1.45',
  sgApiBaseUrlV1:
    import.meta.env.VITE_SG_API_BASE_URL_V1 ||
    'https://api.sallinggroup.com/v1-test/',
  sgApiBaseUrlV2:
    import.meta.env.VITE_SG_API_BASE_URL_V2 ||
    'https://api.sallinggroup.com/v2-test/',
  sgApiNextOrdersUrlV1:
    import.meta.env.VITE_SG_API_NEXT_ORDERS_URL_V1 ||
    'https://api.sallinggroup.com/v1-test/customer-service/next/',
  sgApiNextOrdersUrlV2:
    import.meta.env.VITE_SG_API_NEXT_ORDERS_URL_V2 ||
    'https://api.sallinggroup.com/v2-test/customer-service/next/',
  sgApiNshiftShipments:
    import.meta.env.VITE_SG_API_NSHIFT_SHIPMENTS ||
    'https://api.sallinggroup.com/v1-test/customer-service/next/shipments/',
  sgApiStoreUrl:
    import.meta.env.VITE_SG_API_STORE_URL ||
    'https://api.sallinggroup.com/v2/stores',
  sgImgStoreUrl:
    import.meta.env.VITE_SG_IMG_STORE_URL ||
    'https://res.cloudinary.com/sallingtest/image/upload/',
  sgBearer: import.meta.env.VITE_SG_BEARER || 'b64757e6-d177-4318-97aa-2ccfd12ca642',
  zendeskCustomFieldOrderCallerId:
    import.meta.env.VITE_ZENDESK_CUSTOM_FIELD_ORDER_CALLER_ID ||
    '360000365385',
  zendeskCustomFieldOrderNumber:
    import.meta.env.VITE_ZENDESK_CUSTOM_FIELD_ORDER_NUMBER ||
    '360000360989',
  adClientId:
    import.meta.env.VITE_AD_CLIENT_ID || '0126be4d-187c-400b-9e52-8de6a2cef5c1',
  adTenantId:
    import.meta.env.VITE_AD_TENANT_ID || 'a9d3abc9-3302-4217-8969-726e46809c6d',
  sgApimIdentifierId:
    import.meta.env.VITE_SG_APIM_IDENTIFIER_ID ??
    'G7DCZA1R9J2P6S4DQP478K65A0',
  allowedADLoyaltyRoles:
    import.meta.env.VITE_ALLOWED_AD_LOYALTY_ROLES ||
    'CS.Admin,Support.Loyalty',
  allowedADRoles:
    import.meta.env.VITE_ALLOWED_AD_ROLES ||
    'CS.Admin,CS.TeamLead,CS.TeamCoordinator,CS.Agent,CS.GDPR',
};
