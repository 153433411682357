import React, { useCallback, useMemo } from 'react';
import { formatDate } from '@utils/date-utils/formatDate';
import { currencyFormatter } from '@utils/priceFormatter';
import NoContentMessage from '../../mui-helpers/NoContentMessage';
import { IWalletTransaction } from '@model/wallet';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import Loader from '../../mui-helpers/Loader';
import DataGridWrapper from '../../mui-helpers/DataGridWrapper';
import Box from '@mui/system/Box';
import appState from '@/state';

const WalletTransaction: React.FC = () => {
  const { t } = useTranslation();
  const transactions = appState.loyalty.walletTransactions.data.value?.data;

  const getBalanceColorStyle = useCallback(
    (oldBalance: number, newBalance: number): React.CSSProperties => ({
      color: oldBalance > newBalance ? 'red' : 'green',
    }),
    [],
  );

  const transactionColumns: GridColDef<IWalletTransaction>[] = useMemo(
    () => [
      {
        field: 'amount',
        headerName: t('walletTransaction.amount'),
        flex: 1,
        renderCell: (params) => {
          const balanceChange =
            params.row.oldBalance > params.row.newBalance ? '-' : '';

          return (
            <span
              style={getBalanceColorStyle(
                params.row.oldBalance,
                params.row.newBalance,
              )}
            >
              {balanceChange}
              {currencyFormatter(params.value, params.row.currency)}
            </span>
          );
        },
      },
      {
        field: 'newBalance',
        headerName: t('walletTransaction.newBalance'),
        flex: 1,
        renderCell: (params) =>
          currencyFormatter(params.value, params.row.currency),
      },
      {
        field: 'createdAt',
        headerName: t('walletTransaction.createdAt'),
        flex: 1,
        renderCell: (params) => formatDate(params.value),
      },
    ],
    [transactions, t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const rows = useMemo(
    () =>
      transactions?.map((transaction) => ({
        ...transaction,
        id: transaction.id,
      })) || [],
    [transactions],
  );

  if (!transactions || transactions.length === 0) {
    return <NoContentMessage infoMessage={t('walletTransaction.noData')} />;
  }

  return (
    <Box sx={{ px: 3 }}>
      <Loader area="wallet-transactions-area" />
      <DataGridWrapper
        rows={rows}
        columns={transactionColumns}
        initialSortField="createdAt"
        initialSortOrder="desc"
        pageSize={10}
        pageSizeOptions={[10]}
      />
    </Box>
  );
};

export default WalletTransaction;
