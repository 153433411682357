import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  compensationAvailable: boolean;
  submitAvailable: boolean;

  onClose: () => void;
  onSubmit: () => void;

  loading?: boolean;
}

export const CompensationDialogActions: FC<Props> = ({
  compensationAvailable,
  submitAvailable,
  onClose,
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <DialogActions>
      <Button onClick={onClose}>
        {submitAvailable
          ? t('compensation.dialog.cancel')
          : t('compensation.dialog.close')}
      </Button>

      {compensationAvailable && (
        <Button
          variant="contained"
          loading={loading}
          loadingPosition="start"
          disabled={!submitAvailable}
          onClick={onSubmit}
        >
          {t('compensation.dialog.submit')}
        </Button>
      )}
    </DialogActions>
  );
};

export default CompensationDialogActions;
