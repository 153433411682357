import React from 'react';
import { IPayment } from '@model/receipt';
import { useTranslation } from 'react-i18next';
import { PaymentType } from '@components/loyalty/receipt/PaymentType';
import PaymentDetail from './PaymentDetail';
import PaymentSummary from './PaymentSummary';

interface IPayments {
  payments: IPayment[];
}

const ReceiptPayment: React.FC<IPayments> = ({ payments }) => {
  const { t } = useTranslation();

  const isEmployeeDiscount = (paymentType: string): boolean =>
    paymentType === PaymentType.EMPLOYEE_DISCOUNT;

  const getPaymentLabel = (payment: IPayment): string => {
    switch (payment.paymentType) {
      case PaymentType.CARD_ONLINE:
      case PaymentType.CARD_ONLINE_ALT:
        return t('receipt.paymentType.card');
      case PaymentType.CASH_PAYMENT:
      case PaymentType.CASH_PAYMENT_ALT:
        return t('receipt.paymentType.cash');
      case PaymentType.PANT_VOUCHER:
      case PaymentType.PANT_VOUCHER_ALT:
        return t('receipt.paymentType.voucher');
      case PaymentType.GIFTCARD:
      case PaymentType.GIFTCARD_ALT:
        return t('receipt.paymentType.giftCard');
      case PaymentType.WALLET_PAYMENT:
      case PaymentType.WALLET_PAYMENT_ALT:
        return t('receipt.paymentType.wallet');
      default:
        return payment.paymentTypeLabel ?? payment.paymentType;
    }
  };

  const totalAmount = payments.reduce(
    (sum: number, currentValue: IPayment): number => sum + currentValue.price,
    0,
  );

  const employeeDiscountAmount = payments.reduce(
    (sum, payment) =>
      isEmployeeDiscount(payment.paymentType) ? sum + payment.price : sum,
    0,
  );

  const currency = payments[0].currency;

  return (
    <>
      {employeeDiscountAmount > 0 && (
        <>
          <PaymentSummary
            label={t('receipt.subtotal')}
            amount={totalAmount}
            currency={currency}
          />
          <PaymentDetail
            label={t('receipt.employeeDiscount')}
            amount={employeeDiscountAmount}
            currency={currency}
          />
        </>
      )}
      <PaymentSummary
        label={t('receipt.total')}
        amount={totalAmount - employeeDiscountAmount}
        currency={currency}
      />
      {payments
        .filter((payment) => !isEmployeeDiscount(payment.paymentType))
        .map((payment, index) => (
          <PaymentDetail
            key={payment.paymentType + index}
            label={getPaymentLabel(payment)}
            amount={payment.price}
            currency={currency}
          />
        ))}
    </>
  );
};

export default ReceiptPayment;
