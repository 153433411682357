import React from 'react';
import { addressFormatter } from '@utils/addressFormatter';
import { IAddress } from '@model/order';
import ListItemWithIcon from '../../mui-helpers/ListItemWithIcon';
import theme from '@/theme';

interface DetailItemProps {
  icon: React.ElementType;
  primary: string;
  address: IAddress;
  itemKey: keyof IAddress;
}

const ShippingAndBillingItem: React.FC<DetailItemProps> = ({
  icon: Icon,
  primary,
  address,
  itemKey,
}) => {
  const formatSecondaryText = (
    key: keyof IAddress,
    address: IAddress,
  ): string => {
    switch (key) {
      case 'street':
        return addressFormatter(address);
      default:
        return address[key] ? address[key].toString() : '';
    }
  };

  return (
    <>
      <ListItemWithIcon
        icon={<Icon style={{ color: theme.palette.primary.main }} />}
        primary={primary}
        secondary={formatSecondaryText(itemKey, address)}
      />
    </>
  );
};

export default ShippingAndBillingItem;
