import React from 'react';
import CardContent from '@mui/material/CardContent';
import { IShipment } from '@model/nshift';
import { formatDate } from '@utils/date-utils/formatDate';
import {
  StyledBorderCard,
  StyledDescription,
  StyledTitle,
} from '../../mui-helpers/customComponentStyles';
import { emptyValueReplacement } from '@utils/emptyValueReplacement';

interface ShipmentInfoProps {
  shipment: IShipment;
}

const ShipmentInfo: React.FC<ShipmentInfoProps> = ({ shipment }) => (
  <StyledBorderCard variant="outlined">
    <CardContent sx={{ flex: 1 }}>
      <StyledTitle>Forsendelses</StyledTitle>
      <StyledDescription>
        UUID: {emptyValueReplacement(shipment.uuid)}
        <br />
        Pakke(r): {shipment.packages.length}
        <br />
        Placering: {emptyValueReplacement(shipment.locationName)}
        <br />
        Transportør: {emptyValueReplacement(shipment.carrierName)}
        <br />
        Indsendelsesdato: {formatDate(shipment.submitDate)}
        <br />
        Afhentningsdato: {formatDate(shipment.pickupDate)}
        <br />
        Forsendelses-ID: {emptyValueReplacement(shipment.consignmentId)}
      </StyledDescription>
    </CardContent>
  </StyledBorderCard>
);

export default ShipmentInfo;
