import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import { useNShiftShipments } from '@/service/NShiftService';
import utc from 'dayjs/plugin/utc';
import Loader from '../../mui-helpers/Loader';
import ShipmentSteps from './ShipmentSteps';
import timezone from 'dayjs/plugin/timezone';
import DateRangePicker from '../../mui-helpers/DateRangePicker';
import locale from 'dayjs/locale/da';
import ShipmentDetails from './ShipmentDetails';
import NoContentMessage from '../../mui-helpers/NoContentMessage';
import { enqueueSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(locale);

const currentDate = dayjs();

interface NShiftProps {
  trackingId: string | null;
  invoiceDate: string | null;
}

const NShift: React.FC<NShiftProps> = ({ trackingId, invoiceDate }) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    invoiceDate ? dayjs(invoiceDate) : currentDate.subtract(30, 'day'),
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(
    startDate ? startDate.add(30, 'day') : currentDate,
  );
  const [searchTerm, setSearchTerm] = useState<string>(trackingId || '');

  const {
    data: shipments,
    isLoading,
    refetch,
  } = useNShiftShipments({
    query: searchTerm,
    startDate: startDate?.startOf('day').toISOString() || null,
    endDate: endDate?.endOf('day').toISOString() || null,
  });

  useEffect(() => {
    if (trackingId) {
      handleSearch();
    }
  }, [trackingId, invoiceDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateChange = (startDate: dayjs.Dayjs | null) => {
    setStartDate(startDate);

    let newEndDate = startDate?.add(30, 'day') ?? null;
    if (newEndDate && newEndDate.isAfter(currentDate)) {
      newEndDate = currentDate;
    }
    setEndDate(newEndDate);
  };

  const handleSearch = () => {
    if (!searchTerm) {
      enqueueSnackbar(t('tracking.errors.noTrackingId'), {
        variant: 'warning',
      });
      return;
    }
    refetch();
  };

  const generateShipmentStepsAndDetails = () => {
    if (shipments && shipments.length > 0) {
      return shipments.map((shipment, index) => (
        <Stack
          direction="row"
          spacing={4}
          sx={{ width: '100%', flexWrap: 'wrap-reverse', gap: 4 }}
          key={`${index}-${shipment.uuid}`}
        >
          <ShipmentDetails shipment={shipment} />
          <ShipmentSteps shipment={shipment} />
        </Stack>
      ));
    } else {
      return (
        <NoContentMessage
          infoMessage={t('errors.noDataForTrackingId', {
            trackingId: searchTerm,
          })}
        />
      );
    }
  };

  return (
    <>
      <Paper variant="outlined" sx={{ padding: 2 }}>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ gap: '2rem' }}
        >
          <DateRangePicker
            start={{
              date: startDate ?? dayjs().subtract(30, 'day'),
              minDate: dayjs().subtract(365, 'day'),
              maxDate: dayjs(),
            }}
            end={{
              date: endDate ?? dayjs(),
              disabled: true,
            }}
            onDateChange={handleDateChange}
          />
          <TextField
            fullWidth
            label={t('tracking.searchLabel')}
            placeholder="ES114757463001"
            value={searchTerm}
            sx={{
              background: 'white',
              margin: '0 !important',
              minWidth: '240px',
              maxWidth: '420px',
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton aria-label="Search" onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                ),
              },
            }}
          />
        </Stack>
      </Paper>
      <Box py={4}>
        {isLoading ? (
          <Loader usePromiseTrackerCheck={false} />
        ) : (
          generateShipmentStepsAndDetails()
        )}
      </Box>
    </>
  );
};

export default NShift;
