import React, { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import SearchBar from '../order-details-view/SearchBar';
import { useFirestoreTicketListener } from '@hooks/useFirestoreTicketListener';
import { StyledAppBar } from './shared/styledNavBar';
import { useSettingsContext } from '@contexts/SettingsContext';
import { BrandKey } from '@model/brand';
import appState from '@/state';

const UserAppBar = () => {
  const customerBrand = appState.customer.brand.value;
  const { settings } = useSettingsContext();

  const [manualInput, setManualInput] = useState('');
  const [selectedBrand, setSelectedBrand] = useState<BrandKey>(settings.brand);

  // Set the selected brand to the customer's brand if it exists.
  // This is used when we perform the search from ProgramMemberships.tsx
  useEffect(() => {
    if (customerBrand) {
      setSelectedBrand(customerBrand);
    }
  }, [customerBrand]);

  const showFirestoreDialog = useFirestoreTicketListener();

  return (
    <StyledAppBar position="static">
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <SearchBar
            manualInput={manualInput}
            setManualInput={setManualInput}
            selectedBrand={selectedBrand}
            setSelectedBrand={(brand: BrandKey) => setSelectedBrand(brand)}
          />
        </Box>
      </Toolbar>
      {showFirestoreDialog()}
    </StyledAppBar>
  );
};

export default UserAppBar;
