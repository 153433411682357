import axios, { AxiosRequestConfig } from 'axios';
import { appConfig } from '@/appConfig';
import { trackPromise } from 'react-promise-tracker';
import { IGetMemberOffers, OffersData } from '@model/offer';
import { getCommonHeaders } from './request-helpers/loyalty/getLoyaltyCommonHeaders';
import { performADRequest } from './request-helpers/apiHelper';
import { ICreditCardDetail } from '@components/loyalty/cards/ICreditCardDetail';
import { CustomError } from './CustomError';
import { ICustomerDetailsByBrand, IUser } from '@model/customer';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';
import appState from '@/state';
import { batch } from '@preact/signals';
import { SearchType } from '@utils/checkInputType';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import updateSearchParams from '@utils/updateSearchParams';
import { getBrand } from '@/brandConfig';

export const useCustomerDetailsQuery = (email: string | undefined) => {
  const { t } = useTranslation();

  const userQuery = useQuery<IUser>({
    queryKey: ['customerDetails', email],
    queryFn: () => fetchCustomerDetails(email as string),
    enabled: Boolean(email),
  });

  useEffect(() => {
    if (userQuery.isSuccess) {
      appState.customer.details.value = userQuery.data;
    }

    if (userQuery.isError) {
      console.error('CustomerFetchDetails', JSON.stringify(userQuery.error));
      enqueueSnackbar(t('errors.customerDetailsFetch'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userQuery.dataUpdatedAt, userQuery.errorUpdatedAt]);
};

/**
 * Fetches customer details by the values in the search state.
 *
 * @param queryOptions - The options for the query.
 * @returns the query result object.
 */
export const useLoyaltyCustomerQuery = (
  queryOptions: Partial<
    Omit<UseQueryOptions<ICustomerDetailsByBrand>, 'queryFn' | 'enabled'>
  > = {},
) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const brand = appState.search.brand.value;
  const searchTerm = appState.search.term.value;
  const searchType = appState.search.type.value;

  const query = useQuery({
    queryKey: ['customer', brand, searchTerm],
    queryFn: () => {
      switch (searchType) {
        case SearchType.EMAIL:
          return fetchCustomerDetailsWithBrand(searchTerm!, brand!);
        case SearchType.MEMBER_ID:
          return fetchLoyaltyProfileByMembershipId(searchTerm!);
        case SearchType.GIGYA_ID:
          return fetchLoyaltyProfileByGigyaId(searchTerm!, brand!);
      }

      return Promise.reject(new Error('Invalid search type'));
    },
    enabled: Boolean(brand && searchTerm && searchType !== SearchType.ORDER_ID),
    ...queryOptions,
  });

  useEffect(() => {
    if (query.isSuccess) {
      if (query.data) {
        batch(() => {
          appState.customer.brand.value = query.data.brand ?? undefined;
          appState.customer.details.value = query.data.user;
          appState.customer.profile.value = query.data.profile ?? undefined;

          appState.loyalty.customer.data.value = query.data;
          appState.loyalty.customer.request.value = {
            isFetching: false,
            isFetched: true,
            isError: false,
          };
        });

        setSearchParams(
          updateSearchParams(searchParams, brand!, searchType!, searchTerm!),
        );

        if (query.data.brand && brand !== query.data.brand) {
          enqueueSnackbar(
            t('messages.switchingBrand', {
              brand: getBrand(query.data.brand).label,
            }),
            {
              variant: 'warning',
              autoHideDuration: 4000,
            },
          );
        }
      } else {
        enqueueSnackbar(
          t('errors.customerNotInLoyaltyProgram', {
            brand: brand && getBrand(brand).label,
          }),
        );
        appState.customer.reset();
      }
    } else if (query.isError || query.isRefetchError) {
      appState.loyalty.customer.request.value = {
        isFetching: false,
        isFetched: false,
        isError: true,
      };

      console.error('Error fetching customer details: ', query.error);
      switch (searchType) {
        case SearchType.EMAIL:
          enqueueSnackbar(t('errors.failedToFetchByEmail'));
          break;
        case SearchType.MEMBER_ID:
          enqueueSnackbar(t('errors.failedToFetchByMembershipId'));
          break;
        case SearchType.GIGYA_ID:
          enqueueSnackbar(t('errors.failedToFetchByGigyaId'));
          break;
        default:
          enqueueSnackbar(t('errors.selectSearchType'));
      }
    } else if (query.isFetching || query.isRefetching) {
      appState.loyalty.customer.request.value = {
        isFetching: true,
        isFetched: false,
        isError: false,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.isFetching,
    query.isRefetching,
    query.dataUpdatedAt,
    query.errorUpdatedAt,
  ]);

  return query;
};

/**
 * Fetches member offers based on various criteria.
 *
 * @param {Object} params - The parameters for fetching member offers.
 * @param {string} params.trackingId - The tracking ID of the member.
 * @param {string} params.brand - The brand associated with the member.
 * @param {boolean} [params.isActive] - Whether the offer is active.
 * @param {boolean} [params.isRedeemable] - Whether the offer can be redeemed.
 * @param {Date} [params.validAt=new Date()] - The date at which the offer is valid.
 * @param {string} [params.validFrom] - The start date of the offer validity.
 * @param {string} [params.validUntil] - The end date of the offer validity.
 * @param {string} [params.type] - The type of the offer.
 * @param {string} [params.offerTacticType] - The tactic type of the offer.
 * @param {string[]} [params.offerTactic] - The tactics of the offer.
 * @returns {Promise<OffersData>} A promise that resolves to the offers data.
 */
export const fetchMemberOffers = async ({
  trackingId,
  brand,
  isActive,
  isRedeemable,
  validAt = new Date(),
  validFrom,
  validUntil,
  type,
  offerTacticType,
  offerTactic,
}: IGetMemberOffers): Promise<OffersData> => {
  let queryParams = new URLSearchParams();
  if (isActive !== undefined) queryParams.append('isActive', String(isActive));
  if (validAt) queryParams.append('validAt', validAt.toISOString());
  if (isRedeemable !== undefined)
    queryParams.append('isRedeemable', String(isRedeemable));
  if (validFrom) queryParams.append('validFrom', validFrom);
  if (validUntil) queryParams.append('validUntil', validUntil);
  if (type) queryParams.append('type', type);
  if (offerTacticType) queryParams.append('offerTacticType', offerTacticType);
  if (offerTactic)
    offerTactic.forEach((t) => queryParams.append('offerTactic', t));

  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${trackingId}/offers?${queryParams}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch card details`);
  }
};

/**
 * Fetches the credit card details for a customer.
 *
 * @param cdcId - The SAP Customer Data Cloud ID.
 * @returns A promise that resolves to an array of credit card details.
 * @throws CustomError if the request fails.
 */
export const fetchCardDetails = async (
  cdcId: string,
): Promise<ICreditCardDetail[]> => {
  const url = `${appConfig.sgApiBaseUrlV1}customers/${cdcId}/cards`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch card details`);
  }
};

/**
 * Deletes a loyalty customer by unsubscribing them from the loyalty program.
 *
 * @param brand - The brand associated with the customer.
 * @param gigyaId - The customer's Gigya ID.
 * @returns A promise that resolves when the customer has been successfully unsubscribed.
 * @throws An error if the request to delete the loyalty customer fails.
 */
export const deleteLoyaltyCustomer = async (brand: string, gigyaId: string) => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/unsubscribe/${gigyaId}`;
  try {
    return await performADRequest('POST', url);
  } catch (error) {
    throw new Error(`Failed to delete loyalty: ${error}`);
  }
};

/**
 * Fetches customer details based on the provided email address.
 *
 * @param {string} customerEmail - The email address of the customer to look up.
 * @returns {Promise<IUser>} A promise of type IUser.
 */
const fetchCustomerDetails = async (customerEmail: string): Promise<IUser> => {
  const options: AxiosRequestConfig = {
    method: `GET`,
    url: `${appConfig.sgApiBaseUrlV1}customer-service/customers/lookup?email=${customerEmail}`,
    headers: {
      Authorization: `Bearer ${appConfig.sgBearer}`,
      'Content-Type': `application/json`,
    },
    timeout: 5000,
  };
  return trackPromise(
    axios(options).then((response) => {
      return response.data;
    }),
  );
};

/**
 * Fetches customer details associated with the specified brand.
 *
 * @param customerEmail - The customer's email.
 * @param brand - The selected brand.
 * @returns A promise that resolves to the customer details for the specified brand.
 */
const fetchCustomerDetailsWithBrand = async (
  customerEmail: string,
  brand: string,
): Promise<ICustomerDetailsByBrand> => {
  const encodedEmail = encodeURIComponent(customerEmail);
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/profile?email=${encodedEmail}`;
  const headers = await getCommonHeaders();
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers,
    timeout: 5000,
  };

  return trackPromise(
    axios(url, options).then((response) => {
      return response.data;
    }),
  );
};

/**
 * Fetches the loyalty profile of a customer by their membership ID.
 *
 * @param memberId - The membership ID of the customer.
 * @returns A promise that resolves to the customer's details by brand.
 * @throws Will throw an error if the request fails.
 */
const fetchLoyaltyProfileByMembershipId = async (
  memberId: string,
): Promise<ICustomerDetailsByBrand> => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/loyalty/member/${memberId}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch member by member ID:`);
  }
};

/**
 * Fetches the loyalty profile of a customer by their Gigya ID.
 *
 * @param gigyaId - The customer's Gigya ID.
 * @param brand - The loyalty brand being searched for.
 * @returns A promise that resolves to the customer's details by brand.
 * @throws Will throw an error if the request fails.
 */
const fetchLoyaltyProfileByGigyaId = async (
  gigyaId: string,
  brand: string,
): Promise<ICustomerDetailsByBrand> => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/profile/users/${gigyaId}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch member by gigya ID:`);
  }
};
