import React, { ElementType } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { TypographyProps } from '@mui/material';
import theme from '@/theme';
import HoverListItem from './HoverListItem';

interface ListItemWithIconProps {
  icon: React.ReactNode;
  primary?: string | number;
  secondary?: string | number;
  secondaryTypographyProps?: TypographyProps<ElementType>;
  primaryTypographyProps?: TypographyProps<ElementType>;
  onClick?: () => void;
}
const ListItemWithIcon: React.FC<ListItemWithIconProps> = ({
  icon,
  primary,
  secondary,
  secondaryTypographyProps = {
    variant: 'body1',
    style: { color: theme.palette.primary.main },
  },
  primaryTypographyProps = {
    variant: 'caption',
    style: { color: 'darkgray' },
  },
  onClick,
}) => {
  return (
    <HoverListItem onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      {secondaryTypographyProps && (
        <ListItemText
          primary={primary}
          secondary={secondary}
          slotProps={{
            primary: primaryTypographyProps,
            secondary: secondaryTypographyProps,
          }}
        />
      )}
    </HoverListItem>
  );
};

export default ListItemWithIcon;
