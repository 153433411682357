import { default as loyaltyState } from '@/state/loyalty';
import { default as customerState } from '@/state/customer';
import searchState from '@/state/search';

const appState = {
  search: searchState,
  customer: customerState,
  loyalty: loyaltyState,
};

export default appState;
