import React from 'react';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

interface DataGridWrapperProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  initialSortField: string;
  initialSortOrder: 'asc' | 'desc';
  pageSize: number;
  pageSizeOptions?: number[];
  loading?: boolean;
}

const DataGridWrapper: React.FC<DataGridWrapperProps> = ({
  rows,
  columns,
  initialSortField,
  initialSortOrder,
  pageSize,
  pageSizeOptions = [10],
  loading = false,
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        sx={{
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bolder',
          },
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: pageSize,
            },
          },
          sorting: {
            sortModel: [{ field: initialSortField, sort: initialSortOrder }],
          },
        }}
        pageSizeOptions={pageSizeOptions}
        loading={loading}
        pagination
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default DataGridWrapper;
