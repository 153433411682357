import { useOrdersQuery } from '@/service/OrderService';
import { useCustomerDetailsQuery } from '@/service/CustomerService';
import { Outlet } from 'react-router-dom';

const Orders = () => {
  const ordersQuery = useOrdersQuery();
  const customerEmail = ordersQuery.data?.[0]?.email;
  useCustomerDetailsQuery(customerEmail);

  return <Outlet />;
};

export default Orders;
