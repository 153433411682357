import Box from '@mui/material/Box';
import { Outlet, useMatch } from 'react-router-dom';
import React, { FC } from 'react';
import UserAppBar from '@components/user-app-bar';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Navigation } from '@components/navigation';

interface Props {
  isAuthorizedForOrders: boolean;
  isAuthorizedForLoyalty: boolean;
}

const AppLayout: FC<Props> = ({
  isAuthorizedForOrders,
  isAuthorizedForLoyalty,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isLoyalty = useMatch('/loyalty/*');
  const isOrderSearch = useMatch('/order-search/*');

  return (
    <Box display={isMobile ? 'block' : 'flex'}>
      <Navigation
        isAuthorizedForOrders={isAuthorizedForOrders}
        isAuthorizedForLoyalty={isAuthorizedForLoyalty}
      />
      <main style={{ flex: '1 1 100%', overflowY: 'auto' }}>
        {(isLoyalty || isOrderSearch) && <UserAppBar />}
        <Outlet />
      </main>
    </Box>
  );
};

export default AppLayout;
