import React from 'react';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import CustomerRemarks from './CustomerRemarks';
import Drawer from '@mui/material/Drawer';
import { IOrderDetailsResult } from '@/model/order';
import CommentIcon from '@mui/icons-material/Comment';

interface CustomerRemarksFABProps {
  order: IOrderDetailsResult;
  brand: string;
}

const CustomerRemarksFAB: React.FC<CustomerRemarksFABProps> = ({
  order,
  brand,
}) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          zIndex: '10',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Fab color="primary" aria-label="edit" onClick={toggleDrawer(true)}>
            <CommentIcon />
          </Fab>
        </Box>
      </Box>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        <CustomerRemarks
          anchor="right"
          brand={brand}
          selectedOrderDetails={order}
        />
      </Drawer>
    </>
  );
};

export default CustomerRemarksFAB;
