import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#024a62',
    },
    secondary: {
      main: '#98cbcc',
    },
    error: {
      main: '#d46360',
    },
    warning: {
      main: '#bb8a44',
    },
    info: {
      main: '#7db6d1',
    },
    success: {
      main: '#133f4f',
    },
  },
  typography: {
    fontFamily: '"League Spartan","Roboto", "Helvetica", "Arial", sans-serif',
  },
});

export default theme;
