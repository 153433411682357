import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { SearchOutlined } from '@mui/icons-material';
import { BrandSelect } from '../BrandSelect';
import { useTranslation } from 'react-i18next';
import {
  checkInputType,
  checkLoyaltyInputType,
  sanitizeInput,
  SearchType,
} from '@utils/checkInputType';
import { BrandKey, isBrandKey } from '@model/brand';
import { StyledSearchInput } from '../user-app-bar/shared/styledNavBar';
import Typography from '@mui/material/Typography/Typography';
import { useSearchParams } from 'react-router-dom';
import useAppSection, { AppSection } from '@utils/useAppSection';
import appState from '@/state';

interface SearchBarProps {
  manualInput: string;
  setManualInput: (input: string) => void;
  selectedBrand: BrandKey;
  setSelectedBrand: (brand: BrandKey) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  manualInput,
  setManualInput,
  selectedBrand,
  setSelectedBrand,
}) => {
  const [searchType, setSearchType] = useState<SearchType>(SearchType.EMAIL);
  const [searchParams] = useSearchParams();
  const { isAppSection } = useAppSection();
  const [validationError, setValidationError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const brand = searchParams.get('brand');
    const email = searchParams.get('email');
    const memberId = searchParams.get('memberId');
    const orderId = searchParams.get('orderId');

    if (brand) {
      setSelectedBrand(brand as BrandKey);
    }

    let input: string | undefined = undefined;
    let type: SearchType | undefined;
    if (email) {
      input = email;
      type = SearchType.EMAIL;
    } else if (memberId) {
      input = memberId;
      type = SearchType.MEMBER_ID;
    } else if (orderId) {
      input = orderId;
      type = SearchType.ORDER_ID;
    }

    if (input && type) {
      setManualInput(input);
      setSearchType(type);
    }

    if (brand && isBrandKey(brand) && input && type) {
      const error = handleSanitizationAndValidation(input);
      if (Boolean(error)) {
        setValidationError(error);
      } else {
        appState.search.setSearch(brand, input, type);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSanitizationAndValidation = (input: string): string | null => {
    const sanitizedInput = sanitizeInput(input);
    if (!sanitizedInput) {
      return t('messages.inputRequired');
    }

    const searchType = isAppSection(AppSection.OrderSearch)
      ? checkInputType(sanitizedInput)
      : checkLoyaltyInputType(sanitizedInput);
    setSearchType(searchType);
    return null;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    const error = handleSanitizationAndValidation(input);
    setValidationError(error);
    setManualInput(input);
  };

  const handleSearchClick = () => {
    const sanitizedInput = sanitizeInput(manualInput);

    const error = handleSanitizationAndValidation(sanitizedInput);
    if (error) {
      setValidationError(error);
    } else {
      appState.search.setSearch(selectedBrand, sanitizedInput, searchType);
    }
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  const { inAppSection } = useAppSection();
  const label = inAppSection({
    [AppSection.Loyalty]: t('messages.searchByLoyaltyParameters'),
    [AppSection.OrderSearch]: t('messages.searchByParameters'),
  });

  return (
    <StyledSearchInput
      id="search-bar"
      value={manualInput}
      onChange={handleInputChange}
      onKeyDown={handleKeyPress}
      label={label}
      variant="outlined"
      size="small"
      InputLabelProps={{ style: { color: 'white' } }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <BrandSelect
              selectedBrand={selectedBrand}
              setSelectedBrand={setSelectedBrand}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Typography
              variant="overline"
              color="white"
              sx={{ marginRight: '0.75rem' }}
            >
              {t(`messages.searchExplanation`, { searchType })}
            </Typography>
            <IconButton
              aria-label="search"
              onClick={handleSearchClick}
              edge="end"
              color="secondary"
              sx={{ color: 'white' }}
            >
              <SearchOutlined />
            </IconButton>
          </InputAdornment>
        ),
      }}
      helperText={validationError || ''}
      error={!!validationError}
    />
  );
};

export default SearchBar;
