import { IStore, IShopRequestParams } from '@model/store';
import { trackPromise } from 'react-promise-tracker';
import { IStock, IStockGet, IStockRequestParams } from '@model/stock';
import { enqueueSnackbar } from 'notistack';
import axios, { AxiosRequestConfig } from 'axios';
import { appConfig } from '@/appConfig';

interface IStockService {
  getStoresByBrand(params: IShopRequestParams): Promise<IStore[]>;

  getStocks(params: IStockRequestParams): Promise<IStock>;
}

/**
 * Retrieves stock information based on the provided parameters.
 *
 * @param params - The parameters required to get stock information.
 * @returns A promise that resolves to the stock information.
 * @throws Will throw an error if the request fails.
 *
 */
class StockService implements IStockService {
  public getStoresByBrand = async (
    params: IShopRequestParams,
  ): Promise<IStore[]> => {
    const config: AxiosRequestConfig = {
      method: `GET`,
      url: `${appConfig.sgApiStoreUrl}?brand=${params.brand}&per_page=${params.storesPerPage}`,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': 'application/json',
      },
      timeout: 5000,
    };
    return trackPromise(
      axios(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          enqueueSnackbar({
            message: `Ukendt undtagelse ${error}`,
            autoHideDuration: 4000,
          });
          throw error;
        }),
    );
  };

  /**
   * Retrieves stock information based on the provided parameters.
   *
   * @param params - The parameters required to get stock information.
   * @returns A promise that resolves to the stock information.
   * @throws Will throw an error if the request fails.
   */
  public getStocks = async (params: IStockRequestParams): Promise<IStock> => {
    const config: AxiosRequestConfig<IStockGet> = {
      method: 'POST',
      url: `${appConfig.sgApiBaseUrlV1}stock-levels/articles/${params.articleId}/sites`,
      data: {
        sites: params.siteId,
      },
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': 'application/json',
      },
      timeout: 5000,
    };
    return trackPromise(
      axios(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          const response = error?.response;
          console.error(error);
          enqueueSnackbar({
            message: `Error: ${response?.data?.errorMessage ? response?.data?.errorMessage : response?.data?.error}`,
            autoHideDuration: 4000,
          });
          throw error;
        }),
      'get-stocks',
    );
  };
}

export default StockService;
