import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { IShipment } from '@model/nshift';
import { formatDate } from '@utils/date-utils/formatDate';
import { StyledTitle } from '../../mui-helpers/customComponentStyles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface ShipmentStepsProps {
  shipment: IShipment;
}

const ShipmentSteps: React.FC<ShipmentStepsProps> = ({ shipment }) => {
  const { t } = useTranslation();

  const generateShipmentSteps = () => {
    return shipment.events
      .sort((a, b) => a.date.localeCompare(b.date))
      .map((event) => ({
        title: event.translatedStatusName,
        date: formatDate(event.date),
        translatedTitle: event.statusName,
      }));
  };
  const steps = generateShipmentSteps();

  return (
    <Box sx={{ flex: '1 1 auto' }}>
      <StyledTitle>{t('tracking.shipmentEvents.title')}</StyledTitle>
      <Stepper orientation="vertical" activeStep={shipment.events.length}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              optional={
                <>
                  <Typography>{step.title}</Typography>
                  {step.translatedTitle && (
                    <Typography fontStyle="italic">
                      {step.translatedTitle}
                    </Typography>
                  )}
                </>
              }
            >
              {step.date}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ShipmentSteps;
