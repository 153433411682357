import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { IProductHistory } from '@model/order';
import Divider from '@mui/material/Divider';
import ProductHistoryItem from './ProductHistoryItem';
import { useTranslation } from 'react-i18next';
import {
  StyledBorderCard,
  StyledTitle,
} from '@components/mui-helpers/customComponentStyles';
interface ProductHistoryProps {
  history: IProductHistory[];
  productId: string;
}

const ProductHistory: React.FC<ProductHistoryProps> = ({
  history,
  productId,
}) => {
  const [showAllHistory, setShowAllHistory] = useState(false);
  const toggleShowAllHistory = () => {
    setShowAllHistory(!showAllHistory);
  };
  const { t } = useTranslation();

  return (
    <>
      <StyledTitle variant="h6" my={2}>
        {t('orderDetails.history.title')}
      </StyledTitle>
      <StyledBorderCard variant="outlined" sx={{ padding: 2 }}>
        {history
          .slice(0, showAllHistory ? history.length : 2)
          .map((historyItem, index) => (
            <React.Fragment key={`${index}-${productId}`}>
              <ProductHistoryItem historyItem={historyItem} />
              {index !== history.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        {history.length > 2 && (
          <Button onClick={toggleShowAllHistory} size="small" color="primary">
            {showAllHistory
              ? t('orderDetails.history.showLess')
              : t('orderDetails.history.showMore')}
          </Button>
        )}
      </StyledBorderCard>
    </>
  );
};

export default ProductHistory;
