import React from 'react';
import Typography from '@mui/material/Typography';
import { currencyFormatter } from '@utils/priceFormatter';
import {
  StyledFlexBox,
  StyledTypography,
} from '@components/mui-helpers/customComponentStyles';

interface PaymentDetailProps {
  label: string;
  amount: number;
  currency: string;
}

const PaymentDetail: React.FC<PaymentDetailProps> = ({
  label,
  amount,
  currency,
}) => {
  return (
    <StyledFlexBox>
      <StyledTypography>{label}</StyledTypography>
      <Typography>{currencyFormatter(amount, currency)}</Typography>
    </StyledFlexBox>
  );
};

export default PaymentDetail;
