import ReactDOM from 'react-dom/client';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import AppRouter from './AppRouter';
import './i18n';
import { SettingsProvider } from '@contexts/SettingsContext';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { msalInstance } from '@/service/request-helpers/getAdToken';

const activeAccount = msalInstance.getActiveAccount();

if (!activeAccount) {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authenticationResult = event.payload as AuthenticationResult;
    const account = authenticationResult.account;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance.enableAccountStorageEvents();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ThemeProvider theme={theme}>
    <MsalProvider instance={msalInstance}>
      <SettingsProvider>
        <AppRouter />
      </SettingsProvider>
    </MsalProvider>
  </ThemeProvider>,
);
