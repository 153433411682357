import React from 'react';
import OrderTable from '../search-result-table/OrderTable';
import Loader from '../../mui-helpers/Loader';
import Box from '@mui/material/Box';

const OrderSearch = () => {
  return (
    <Box sx={{ padding: '1rem' }}>
      <Loader area="full-order-details" />
      <OrderTable />
    </Box>
  );
};

export default OrderSearch;
