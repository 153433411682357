import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { appConfig } from '@/appConfig';
import getAdToken from '@/service/request-helpers/getAdToken';

/**
 * Obtain the Azure AD token and privileges for the currently active account.
 */
export const useAuthorizationHook = () => {
  const { instance, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const isAuthenticated = useIsAuthenticated();
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  const [isLoyaltyAuthorized, setIsLoyaltyAuthorized] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    const checkRoles = async () => {
      if (!isAuthenticated || inProgress !== InteractionStatus.None) {
        return;
      }

      const account = instance.getActiveAccount();
      if (!account) {
        setIsAuthorized(false);
        setIsLoyaltyAuthorized(false);
        return;
      }

      try {
        const { accessToken } = await getAdToken(account);

        const roles = new Set(account.idTokenClaims?.roles ?? []);

        const isUserAuthorized = !roles.isDisjointFrom(
          new Set(appConfig.allowedADRoles.split(',')),
        );
        const isLoyaltyUserAuthorized = !roles.isDisjointFrom(
          new Set(appConfig.allowedADLoyaltyRoles.split(',')),
        );

        setAccessToken(accessToken);
        setIsAuthorized(isUserAuthorized);
        setIsLoyaltyAuthorized(isLoyaltyUserAuthorized);
      } catch (error) {
        console.error(error);
        setAccessToken(null);
        setIsAuthorized(false);
        setIsLoyaltyAuthorized(false);
      }
    };

    if (isAuthenticated) {
      checkRoles();
    }
  }, [isAuthenticated, inProgress, instance]);

  return { isAuthorized, isLoyaltyAuthorized, accessToken };
};
