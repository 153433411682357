import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import OfferDetails from './OfferDetails';
import { MemberOffer } from '@model/offer';
import { containsSearchTerm } from '@utils/containsSearchTerm';
import ProductList from './ProductList';
import NoContentMessage from '../../mui-helpers/NoContentMessage';
import CustomFilterOptions from '../../mui-helpers/CustomFilterOptions';
import dayjs, { Dayjs } from 'dayjs';
import { fetchMemberOffers } from '@/service/CustomerService';
import { useTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import Loader from '../../mui-helpers/Loader';
import OfferList from './OfferList';
import { isOfferMembership } from '@utils/isOfferMembership';
import CustomerDetails from '../../order-details-view/CustomerDetails';
import { enqueueSnackbar } from 'notistack';
import appState from '@/state';

const Offers: React.FC = () => {
  const initialOffers = appState.loyalty.memberOffers.data.value?.memberOffers;

  const [offers, setOffers] = useState<MemberOffer[] | undefined>(
    initialOffers,
  );

  const customerBrand = appState.customer.brand.value;
  const profileDetails = appState.customer.profile.value;

  const [selectedOffer, setSelectedOffer] = useState<MemberOffer | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    validAt: dayjs() as Dayjs | null,
    membership: true,
    personal: true,
  });

  const { t } = useTranslation();

  const noFiltersApplied = useCallback(() => {
    return !filters.membership && !filters.personal;
  }, [filters]);

  useEffect(() => {
    setOffers(initialOffers);
  }, [initialOffers]);

  useEffect(() => {
    if (Array.isArray(offers) && offers.length > 0) {
      setSelectedOffer(offers[0]);
    }
  }, [offers]);

  useEffect(() => {
    if (noFiltersApplied()) {
      setSearchTerm('');
    }
  }, [noFiltersApplied]);

  const handleApplyFilters = useCallback(async () => {
    const { validAt } = filters;

    if (!validAt) {
      setSelectedOffer(initialOffers ? initialOffers[0] : null);
      setOffers(initialOffers);
      setFilters({
        ...filters,
        membership: true,
        personal: true,
      });
      return;
    }

    if (profileDetails?.trackingId) {
      const updatedOffers = await trackPromise(
        fetchMemberOffers({
          trackingId: profileDetails.trackingId,
          brand: customerBrand!,
          validAt: validAt.toDate(),
        }),
        'updated-offers-area',
      );

      if (updatedOffers) {
        setOffers(updatedOffers.memberOffers);
        setFilters({
          ...filters,
          membership: true,
          personal: true,
        });
      } else {
        enqueueSnackbar(t('offerDetails.noData'));
      }
    }
  }, [customerBrand, filters, initialOffers, CustomerDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClearFilters = () => {
    setFilters({
      validAt: null,
      membership: false,
      personal: false,
    });
    setSelectedOffer(null);
  };

  const filterOffers = (offers: MemberOffer[]): MemberOffer[] => {
    return offers.filter((offerItem) => {
      const matchesSearchTerm = containsSearchTerm(offerItem, searchTerm);
      const isMembershipOffer = isOfferMembership(offerItem.offer);
      const matchesOfferType =
        (filters.membership && isMembershipOffer) ||
        (filters.personal && !isMembershipOffer);
      return matchesSearchTerm && matchesOfferType;
    });
  };

  const checkboxOptions = [
    {
      checked: filters.membership,
      key: 'membership',
      label: 'Membership',
    },
    {
      checked: filters.personal,
      key: 'personal',
      label: 'Personal',
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Loader area="updated-offers-area" />
      <CustomFilterOptions
        dateType="validAt"
        dateValue={filters.validAt}
        checkboxes={checkboxOptions}
        handleApplyFilters={handleApplyFilters}
        setFilters={setFilters}
        onClearFilters={handleClearFilters}
      />
      <Box
        sx={{
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        {noFiltersApplied() ? (
          <NoContentMessage infoMessage={t('offerDetails.noData')} />
        ) : (
          <Grid container width="100%">
            <Grid item xs={12} sm={4} md={3} lg={2.2} xl={2.3}>
              <OfferList
                offers={offers}
                selectedOffer={selectedOffer}
                setSelectedOffer={setSelectedOffer}
                setSearchTerm={setSearchTerm}
                filterOffers={filterOffers}
              />
            </Grid>
            {selectedOffer && (
              <>
                <Grid item xs={12} sm={8} md={3.5} lg={2.8} xl={1.9}>
                  <OfferDetails offer={selectedOffer} />
                </Grid>
                <Grid item xs={12} sm={12} md={5.5} lg={7} xl={7.8}>
                  <ProductList products={selectedOffer.products} />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default Offers;
