import React from 'react';
import Grid from '@mui/material/Grid2';
import { IProduct } from '@model/order';
import { useTranslation } from 'react-i18next';
import {
  StyledBorderCard,
  StyledTitle,
} from '@components/mui-helpers/customComponentStyles';
import CaptionedText from '@components/mui-helpers/CaptionedText';
import { currencyFormatter } from '@/utils/priceFormatter';
import Box from '@mui/material/Box';
interface ProductPriceProps {
  product: IProduct;
}

const ProductPrice: React.FC<ProductPriceProps> = ({ product }) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledTitle variant="h6" my={2}>
        {t('orderDetails.product.priceTitle')}
      </StyledTitle>
      <StyledBorderCard sx={{ padding: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          {' '}
          {[
            {
              caption: t('orderDetails.product.totalDiscount'),
              text: currencyFormatter(product.totalDiscount, 'DKK'),
            },
            {
              caption: t('orderDetails.product.totalPrice'),
              text: currencyFormatter(product.totalPrice, 'DKK'),
            },
            {
              caption: t('orderDetails.product.unitPrice'),
              text: currencyFormatter(product.unitPrice, 'DKK'),
            },
            {
              caption: t('orderDetails.product.netPrice'),
              text: currencyFormatter(product.netPrice, 'DKK'),
            },
          ].map(({ caption, text }, index) => (
            <CaptionedText
              key={index}
              caption={caption}
              text={text}
              sx={{ minWidth: '100px' }}
            />
          ))}
        </Box>
      </StyledBorderCard>
    </>
  );
};

export default ProductPrice;
