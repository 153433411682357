import { batch, signal } from '@preact/signals';
import { SearchType } from '@utils/checkInputType';
import { BrandKey } from '@model/brand';

export const createDefaultSearchState = () => {
  const brand = signal<BrandKey | undefined>(undefined);
  const term = signal<string | undefined>(undefined);
  const type = signal<SearchType | undefined>(undefined);

  const clearSearch = () => {
    brand.value = undefined;
    term.value = undefined;
    type.value = undefined;
  };

  const setSearch = (
    brandKey: BrandKey,
    searchTerm: string,
    searchType: SearchType,
  ) => {
    batch(() => {
      brand.value = brandKey;
      term.value = searchTerm;
      type.value = searchType;
    });
  };

  const searchState = {
    brand,
    term,
    type,
    setSearch,
    clearSearch,
  };

  return searchState;
};

const searchState = createDefaultSearchState();

export default searchState;
