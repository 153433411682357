import {
  AccountInfo,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from '@/config/authConfig';

export const msalInstance =
  await PublicClientApplication.createPublicClientApplication(msalConfig);

export interface ITokens {
  accessToken: string;
  idToken: string;
}

/**
 * Obtain the Azure AD token for the currently active account.
 *
 * First tries a silent token acquisition which either uses the account in the
 * cache or relies on third-party cookies in browsers that allow them, such as
 * Chrome. In case the browser doesn't allow them, or we don't have an active
 * account, it will redirect to the login page.
 *
 * We deliberately use a “silent” token acquisition here, to ensure that the
 * token is refreshed whenever possible.
 */
export default async (account?: AccountInfo): Promise<ITokens> => {
  const request = {
    scopes: ['User.Read', 'AppRoleAssignment.ReadWrite.All'],
    account: account,
  };

  try {
    const { accessToken, idToken } =
      await msalInstance.acquireTokenSilent(request);
    return { accessToken, idToken };
  } catch (error) {
    // Our token is expired, and interaction is required so try redirecting;
    // that means leaving the app, so there's nothing to return
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(request);
    }

    throw error;
  }
};
