import React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { IProduct } from '@/model/order';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import theme from '@/theme';
import { StyledBorderCard } from '@components/mui-helpers/customComponentStyles';
import CaptionedText from '@components/mui-helpers/CaptionedText';
import { useTranslation } from 'react-i18next';
import ProductDialog from './ProductDialog';
import ListItem from '@mui/material/ListItem';
import HoverListItem from '@components/mui-helpers/HoverListItem';

interface ProductListProps {
  products: IProduct[];
}
const ProductList: React.FC<ProductListProps> = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = React.useState<IProduct | null>(
    null,
  );
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    setSelectedProduct(null);
  };

  return (
    <Stack spacing={2} mt={6}>
      <StyledBorderCard>
        {products.map((product, index) => (
          <React.Fragment key={`${index}-${product.productId}`}>
            <HoverListItem onClick={() => setSelectedProduct(product)}>
              <ListItem>
                <Stack>
                  <Typography sx={{ color: theme.palette.primary.main }}>
                    {product.name}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, flexFlow: 'wrap' }}>
                    <CaptionedText
                      caption={t('orderDetails.product.articleId')}
                      text={product.articleId}
                    />
                    <CaptionedText
                      caption={t('orderDetails.product.status')}
                      text={product.status}
                    />
                    <CaptionedText
                      caption={t('orderDetails.product.totalPrice')}
                      text={product.totalPrice}
                    />
                    <CaptionedText
                      caption={t('orderDetails.product.quantity')}
                      text={product.quantity}
                    />
                  </Box>
                </Stack>
              </ListItem>
            </HoverListItem>
            {index < products.length - 1 && <Divider variant="fullWidth" />}
          </React.Fragment>
        ))}
      </StyledBorderCard>
      <ProductDialog
        selectedProduct={selectedProduct}
        handleCloseDialog={handleCloseDialog}
      />
    </Stack>
  );
};

export default ProductList;
