import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledHeader } from '../mui-helpers/customComponentStyles';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <StyledHeader>{t('messages.notFound')}</StyledHeader>
      <p>{t('messages.pageNotFound')}</p>
    </div>
  );
};

export default NotFound;
