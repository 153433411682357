import { IProfile, IUser } from '@model/customer';
import { IOrderResult } from '@model/order';
import { batch, effect, signal } from '@preact/signals';
import { BrandKey } from '@model/brand';

export const createDefaultCustomerState = () => {
  const customerState = {
    brand: signal<BrandKey | undefined>(undefined),
    details: signal<IUser | undefined>(undefined),
    profile: signal<IProfile | undefined>(undefined),
    address: signal<string>(''),
    email: signal<string>(''),
    orders: signal<IOrderResult[] | undefined>(undefined),
    reset: () => {
      batch(() => {
        customerState.brand.value = undefined;
        customerState.details.value = undefined;
        customerState.profile.value = undefined;
        customerState.address.value = '';
        customerState.email.value = '';
        customerState.orders.value = undefined;
      });
    },
  };

  effect(() => {
    if (customerState.details.value) {
      customerState.email.value = customerState.details.value.email;
    }
  });

  return customerState;
};

const customerState = createDefaultCustomerState();

export default customerState;
