import React, { useRef, useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { convertToSapDateFormat } from '@/utils/date-utils/convertToSapDateFormat';
import { OrderService } from '@/service/OrderService';
import { IOrderDetailsResult } from '@/model/order';
import { hashToColor } from '@/utils/hashToColor';
import './customerRemarks.css';

interface CustomerRemarksProps {
  anchor: string;
  brand: string;
  selectedOrderDetails: IOrderDetailsResult;
}
const CustomerRemarks: React.FC<CustomerRemarksProps> = ({
  anchor,
  brand,
  selectedOrderDetails,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [customerRemarks, setCustomerRemarks] = useState<string[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const orderService: OrderService = new OrderService();

  const { instance } = useMsal();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedOrderDetails && selectedOrderDetails.customerRemarks) {
      setCustomerRemarks(selectedOrderDetails.customerRemarks);
    }
  }, [selectedOrderDetails]);

  const addCustomerRemark = async () => {
    if (inputValue) {
      const account = instance.getActiveAccount();
      const firstName = account?.name ? account.name.split(' ')[0] : '';
      const email = account?.username ? account.username : '';

      const remarkText = `${convertToSapDateFormat(new Date())} ${email} ${firstName} ${inputValue}`;

      await orderService.postCustomerRemark({
        brand: brand as string,
        orderId: selectedOrderDetails.orderNumber,
        //erpOrderNumber in SAP has an additional "00" at the start
        erpOrderNumber: '00' + selectedOrderDetails.erpOrderNumber,
        customerRemarks: [remarkText],
      });

      setCustomerRemarks((prevRemarks) => [...prevRemarks, remarkText]);
      setInputValue('');
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [customerRemarks]);

  return (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350,
      }}
      role="presentation"
    >
      <Stack
        spacing={2}
        sx={{
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between',
          height: '100vh',
        }}
      >
        <List
          sx={{
            overflowY: 'auto',
          }}
        >
          {customerRemarks.map((message, index) => (
            <ListItem key={`${index}-${message}`}>
              <Box
                key={index}
                display="flex"
                alignItems="flex-start"
                mt={2}
                maxWidth={'90%'}
              >
                <Avatar
                  style={{
                    backgroundColor: hashToColor(
                      typeof message === 'string' ? message.substring(13) : '',
                    ),
                    marginRight: '2rem',
                  }}
                  src="/broken-image.jpg"
                />
                <Box maxWidth={'80%'}>
                  <div
                    className={`speech-bubble ${
                      index % 2 === 0 ? 'dark-blue' : 'blue'
                    }`}
                  >
                    <Typography
                      component="div"
                      gutterBottom
                      sx={{ overflowWrap: 'break-word' }}
                    >
                      {message}
                    </Typography>
                  </div>
                </Box>
              </Box>
            </ListItem>
          ))}
          <div ref={messagesEndRef}></div>
        </List>
        <TextField
          fullWidth
          placeholder={t('orderDetails.remark.addNote')}
          value={inputValue}
          sx={{
            background: 'white',
            margin: '0 !important',
            minWidth: '240px',
            maxWidth: '420px',
            position: 'sticky',
            bottom: 2,
          }}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              addCustomerRemark();
            }
          }}
          slotProps={{
            input: {
              endAdornment: (
                <IconButton aria-label="Send" onClick={addCustomerRemark}>
                  <SendIcon />
                </IconButton>
              ),
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default CustomerRemarks;
