import React from 'react';
import Typography from '@mui/material/Typography';
import { IOrderDetailsResult } from '@model/order';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';
import { formatDate } from '@/utils/date-utils/formatDate';
import { priceFormatter } from '@/utils/priceFormatter';
import {
  StyledBorderCard,
  StyledDescription,
  StyledHeader,
} from '@/components/mui-helpers/customComponentStyles';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CompensationDialog from '@components/compensation/CompensationDialog';
import { CurrencyExchange } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface OrderDetailsHeaderProps {
  order: IOrderDetailsResult;
}

const OrderDetailsHeader: React.FC<OrderDetailsHeaderProps> = ({ order }) => {
  const { t } = useTranslation();
  const [isCompensationDialogOpen, setIsCompensationDialogOpen] =
    React.useState(false);

  const fields: { label: string; value: any }[] = [
    {
      label: t('orderDetails.header.customerName'),
      value: emptyValueReplacement(order.billingAddress.name),
    },
    {
      label: t('orderDetails.header.totalAmount'),
      value: (
        <>
          <Tooltip
            title={
              <>
                <div>
                  {`${t('orderDetails.header.totalDiscount')}: ${priceFormatter(order.totalDiscount || 0)}`}
                </div>
                <div>
                  {`${t('orderDetails.header.deliveryPrice')}: ${priceFormatter(order.deliveryPrice || 0)}`}
                </div>
                <div>
                  {`${t('orderDetails.header.totalAmount')}: ${priceFormatter(order.total || 0)}`}
                </div>
              </>
            }
          >
            <span>
              {priceFormatter(
                (order.total || 0) +
                  (order.deliveryPrice || 0) -
                  (order.totalDiscount || 0),
              )}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      label: t('orderDetails.header.orderDate'),
      value: order.orderDate ? formatDate(order.orderDate) : 'N/A',
    },
    {
      label: t('orderDetails.header.salesOffice'),
      value: order.salesOfficeDescription
        ? `${order.salesOfficeDescription}(${order.salesOfficeId})`
        : 'N/A',
    },
  ];

  return (
    <>
      <CompensationDialog
        brand={'bilka'}
        orderId={order.orderNumber}
        open={isCompensationDialogOpen}
        onClose={() => setIsCompensationDialogOpen(false)}
      />
      <Stack direction="row" spacing={2} justifyContent="space-between" mb={1}>
        <StyledHeader>
          {t('orderDetails.header.title')} #{order.orderNumber}{' '}
        </StyledHeader>
        <Button
          variant="contained"
          onClick={() => setIsCompensationDialogOpen(true)}
          startIcon={<CurrencyExchange />}
        >
          {t('compensation.button')}
        </Button>
      </Stack>

      <StyledBorderCard>
        <Grid container p={2}>
          {fields.map((field, index) => (
            <Grid
              key={`${index}-${field.label}`}
              size={{ xs: 6, md: 3, lg: 2 }}
            >
              <StyledDescription display="block">
                {field.label}
              </StyledDescription>
              <Typography
                fontSize=".8rem"
                sx={{ fontWeight: 'medium' }}
                color="primary"
              >
                {field.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </StyledBorderCard>
    </>
  );
};

export default OrderDetailsHeader;
