import React from 'react';
import { IShipment } from '@model/nshift';
import AddressDetails from './AddressDetails';
import ShipmentInfo from './ShipmentInfo';
import { Stack } from '@mui/material';

interface ShipmentDetailsProps {
  shipment: IShipment;
}

const ShipmentDetails: React.FC<ShipmentDetailsProps> = ({ shipment }) => {
  return (
    <Stack spacing={2} sx={{ flex: '1 1 auto' }}>
      <ShipmentInfo shipment={shipment} />
      <AddressDetails title="Modtager" address={shipment.receiver} />
      <AddressDetails title="Afsender" address={shipment.sender} />
    </Stack>
  );
};

export default ShipmentDetails;
