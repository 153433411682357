import NShift from '@components/order-details-view/nShift-tab';
import React from 'react';
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import {
  StyledDescription,
  StyledHeader,
} from '@components/mui-helpers/customComponentStyles';
import Button from '@mui/material/Button';
import { Stack, useMediaQuery } from '@mui/material';
import ShoppingCart from '@components/icons/ShoppingCart';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { formatDate } from '@utils/date-utils/formatDate';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  definitionList: {
    '& > dt': { ...theme.typography.overline },
    '& > dd': { ...theme.typography.body1 },
  },
}));

const TrackingView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();

  const classes = useStyles();

  const { trackingId } = useParams<{ trackingId?: string }>();

  const [searchParams] = useSearchParams();
  const brand = searchParams.get('brand');
  const orderId = searchParams.get('orderId');
  const date = searchParams.get('date');

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box p={3} sx={{ width: '100%', maxWidth: '1440px' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box pb={3}>
              <StyledHeader>{t('tracking.title')}</StyledHeader>
              <StyledDescription>{t('tracking.subtitle')}</StyledDescription>
            </Box>

            {brand && orderId && date && (
              <Stack direction="row" spacing={2}>
                {isMobile && (
                  <IconButton
                    color="primary"
                    component={RouterLink}
                    to={`/order-search/brands/${brand}/orders/${orderId}`}
                  >
                    <ShoppingCart />
                  </IconButton>
                )}
                {!isMobile && (
                  <>
                    <Button
                      component={RouterLink}
                      variant="contained"
                      startIcon={<ShoppingCart />}
                      to={`/order-search/brands/${brand}/orders/${orderId}`}
                    >
                      {t('tracking.order.button')}
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <dl className={classes.definitionList}>
                      <dt>{t('tracking.order.orderNo')}</dt>
                      <dd>{orderId}</dd>
                    </dl>
                    <dl className={classes.definitionList}>
                      <dt>{t('tracking.order.orderDate')}</dt>
                      <dd>{formatDate(date)}</dd>
                    </dl>
                  </>
                )}
              </Stack>
            )}
          </Stack>
          <NShift trackingId={trackingId ?? null} invoiceDate={date ?? null} />
        </Box>
      </Box>
    </>
  );
};

export default TrackingView;
