import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import OrderSearch from '@components/order-search-view/order-details/OrderSearch';
import StocksLayout from '@components/order-search-view/stocks-view/StocksLayout';
import OrderDetailsTab from '@components/order-search-view/order-details/OrderDetails';
import NotFound from '@components/./not-found/NotFound';
import Loyalty from '@components/loyalty/Loyalty';
import { useAuthorizationHook } from '@hooks/useAuthorizationHook';
import Loader from '@components/mui-helpers/Loader';
import ProfilePage from '@components/profile/ProfilePage';
import { useSettingsContext } from '@contexts/SettingsContext';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';
import SnackbarCloseButton from '@components/SnackbarCloseButton';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppSection } from '@utils/useAppSection';
import CustomerDetails from '@components/order-details-view/CustomerDetails';
import WalletDetailsHeader from '@components/loyalty/wallet/WalletDetailsHeader';
import WalletTransaction from '@components/loyalty/wallet/WalletTransactions';
import Offers from '@components/loyalty/offer/Offers';
import PunchCardList from '@components/loyalty/trade-drivers/PunchCardList';
import Receipt from '@components/loyalty/receipt/Receipt';
import { TabIds } from '@model/LoyaltyTabsConfig';
import LoyaltyTab from '@components/loyalty/LoyaltyTab';
import appState from '@/state';
import AppLayout from '@components/AppLayout';
import TrackingView from '@components/tracking-view/TrackingView';
import Orders from '@components/Orders';

export const loyaltyRoute = '/loyalty';
export const orderSearchRoute = '/order-search';

const queryClient = new QueryClient();

const AppRouter = () => {
  const isAuthenticated = useIsAuthenticated();
  const { isAuthorized, isLoyaltyAuthorized } = useAuthorizationHook();
  const { settings } = useSettingsContext();

  // If the user is authorized and loyalty is authorized, the default route is
  // whatever the initial view is set to in the settings. Otherwise, use the
  // only route the user has access to.
  const getDefaultRoute = () => {
    if (isAuthorized && isLoyaltyAuthorized) {
      switch (settings.initialView) {
        case AppSection.Loyalty:
          return loyaltyRoute;
        case AppSection.OrderSearch:
          return orderSearchRoute;
      }
    } else if (isAuthorized) {
      return orderSearchRoute;
    } else if (isLoyaltyAuthorized) {
      return loyaltyRoute;
    } else {
      return null;
    }
  };

  if (
    (isAuthorized === null || isLoyaltyAuthorized === null) &&
    isAuthenticated
  ) {
    return <Loader loadingMessage="Indlæser" />;
  }

  if (!isAuthenticated) {
    return (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <div>Redirecting to login...</div>
      </MsalAuthenticationTemplate>
    );
  }

  const defaultRoute = getDefaultRoute();

  if (
    (isAuthorized === null || isLoyaltyAuthorized === null) &&
    isAuthenticated
  ) {
    return <Loader loadingMessage="Indlæser" />;
  }

  if (!isAuthenticated) {
    return (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <div>Redirecting to login...</div>
      </MsalAuthenticationTemplate>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={6000}
        variant="error"
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        action={(snackbarKey) => (
          <SnackbarCloseButton snackbarKey={snackbarKey} />
        )}
      >
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <AppLayout
                  isAuthorizedForOrders={Boolean(isAuthorized)}
                  isAuthorizedForLoyalty={Boolean(isLoyaltyAuthorized)}
                />
              }
            >
              <>
                {isAuthorized && (
                  <>
                    <Route path={orderSearchRoute} element={<Orders />}>
                      <Route index element={<OrderSearch />} />
                      <Route
                        path="brands/:brand/orders/:orderId"
                        element={<OrderDetailsTab />}
                      />
                    </Route>
                    <Route path="/stocks" element={<StocksLayout />} />
                    <Route
                      path="order-search/brands/:brand/orders/:orderId"
                      element={<OrderDetailsTab />}
                    />
                    <Route
                      path="/tracking/:trackingId?"
                      element={<TrackingView />}
                    />
                    <Route path="profile" element={<ProfilePage />} />
                  </>
                )}
                {(isAuthorized || isLoyaltyAuthorized) && (
                  <Route path="profile" element={<ProfilePage />} />
                )}
                {isLoyaltyAuthorized && (
                  <>
                    <Route
                      path={loyaltyRoute}
                      element={
                        <Navigate
                          to={`${loyaltyRoute}/${TabIds.CUSTOMER_PROFILE}`}
                          replace
                        />
                      }
                    />
                    <Route path={loyaltyRoute} element={<Loyalty />}>
                      <Route
                        path={TabIds.CUSTOMER_PROFILE}
                        element={
                          <LoyaltyTab tab={TabIds.CUSTOMER_PROFILE}>
                            <CustomerDetails />
                          </LoyaltyTab>
                        }
                      />
                      <Route
                        path={TabIds.WALLET}
                        element={
                          <LoyaltyTab
                            tab={TabIds.WALLET}
                            prerequisiteRequests={[
                              appState.loyalty.wallet.request,
                              appState.loyalty.walletTransactions.request,
                            ]}
                          >
                            <WalletDetailsHeader />
                            <WalletTransaction />
                          </LoyaltyTab>
                        }
                      />
                      <Route
                        path={TabIds.MEMBER_OFFERS}
                        element={
                          <LoyaltyTab
                            tab={TabIds.MEMBER_OFFERS}
                            prerequisiteRequests={[
                              appState.loyalty.memberOffers.request,
                            ]}
                          >
                            <Offers />
                          </LoyaltyTab>
                        }
                      />
                      <Route
                        path={TabIds.PUNCH_CARDS}
                        element={
                          <LoyaltyTab
                            tab={TabIds.PUNCH_CARDS}
                            prerequisiteRequests={[
                              appState.loyalty.punchCards.request,
                            ]}
                          >
                            <PunchCardList />
                          </LoyaltyTab>
                        }
                      />
                      <Route
                        path={TabIds.RECEIPTS}
                        element={
                          <LoyaltyTab
                            tab={TabIds.RECEIPTS}
                            prerequisiteRequests={[
                              appState.loyalty.receipts.request,
                            ]}
                          >
                            <Receipt />
                          </LoyaltyTab>
                        }
                      />
                    </Route>
                  </>
                )}
                {defaultRoute && (
                  <Route
                    path="/"
                    element={<Navigate to={defaultRoute} replace />}
                  />
                )}
                <Route path="*" element={<NotFound />} />
              </>
            </Route>
          </Routes>
        </Router>
      </SnackbarProvider>
    </QueryClientProvider>
  );
};

export default AppRouter;
