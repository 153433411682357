import React, { useMemo } from 'react';
import { IAccountData } from '@model/customer';
import { useTranslation } from 'react-i18next';
import {
  StyledDescription,
  StyledHeader,
} from '../../mui-helpers/customComponentStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ProgramCard from './ProgramCard';
import { AppSection, getSupportedBrands } from '@utils/useAppSection';

interface CustomerProgramProps {
  accountData: IAccountData;
}

const ProgramMemberships: React.FC<CustomerProgramProps> = ({
  accountData,
}) => {
  const { customerProgram } = accountData;
  const { t } = useTranslation();

  /**
   * Reduces the list of brands to an array of brand details where the customer has a membership Id or signup date for the brand.
   */
  const customerBrandDetails = useMemo(() => {
    return getSupportedBrands(AppSection.Loyalty)
      .filter(
        (brand) =>
          customerProgram && customerProgram[brand.customerProgramMembershipNr],
      )
      .map((brand) => ({
        brand,
        membershipNr: customerProgram![brand.customerProgramMembershipNr],
        signupDate: customerProgram![brand.customerProgramSignupDate],
      }));
  }, [customerProgram]);

  const renderNoPrograms = () => {
    if (customerProgram === null || customerBrandDetails.length === 0) {
      return (
        <StyledDescription sx={{ padding: '1rem' }}>
          {t('customerProgram.noPrograms')}
        </StyledDescription>
      );
    }
    return null;
  };

  const renderProgramMemberships = () => (
    <Grid container paddingX={4} gap={4}>
      {customerBrandDetails.map((customerBrandDetail, index) => (
        <ProgramCard
          customerBrandDetails={customerBrandDetail}
          index={index}
          key={`${customerBrandDetail.brand.key}-${index}`}
        />
      ))}
    </Grid>
  );

  return (
    <Accordion sx={{ my: 2 }} defaultExpanded component={Paper} elevation={3}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="program-memberships"
      >
        <StyledHeader gutterBottom>{t('customerProgram.title')}</StyledHeader>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {renderProgramMemberships()}
          {renderNoPrograms()}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default ProgramMemberships;
