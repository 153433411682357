import React, { JSX, useMemo, useState } from 'react';
import { useAccount } from '@azure/msal-react';
import ListItemWithIcon from '../mui-helpers/ListItemWithIcon';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Security from '@mui/icons-material/Security';
import Tune from '@mui/icons-material/Tune';
import {
  StyledDescription,
  StyledTitle,
} from '../mui-helpers/customComponentStyles';
import { useTranslation } from 'react-i18next';
import DetailItem from '../mui-helpers/DetailItem';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { getFlag, locales, toLocale } from '@/i18n';
import {
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useAuthorizationHook } from '@hooks/useAuthorizationHook';
import { useSettingsContext } from '@contexts/SettingsContext';
import {
  AppSection,
  getSupportedBrands,
  toAppSection,
} from '@utils/useAppSection';
import { BrandKey } from '@model/brand';

const ProfilePage: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<string>('settings');
  const { settings, setSettings } = useSettingsContext();
  const { t } = useTranslation();
  const account = useAccount();
  const { isAuthorized, isLoyaltyAuthorized } = useAuthorizationHook();

  if (!account) {
    throw new Error('No active account found');
  }

  const agentInfo = useMemo(
    () => ({
      name: account.name || 'Agent Name',
      roles: account.idTokenClaims?.roles ?? [],
      email: account.username,
      salaryId: String(account.idTokenClaims?.salaryId ?? ''),
    }),
    [account],
  );

  const handleLanguageChange = (event: SelectChangeEvent) => {
    const locale = toLocale(event.target.value);
    if (locale !== settings.locale) {
      setSettings({
        locale: locale,
        brand: settings.brand,
        initialView: settings.initialView,
      });
    }
  };

  const handleInitialViewChange = (event: SelectChangeEvent) => {
    let initialView = toAppSection(event.target.value);
    console.log(initialView, settings.initialView, event.target.value);
    if (initialView !== settings.initialView) {
      setSettings({
        locale: settings.locale,
        brand: settings.brand,
        initialView: initialView,
      });
    }
  };

  const handleBrandChange = (event: SelectChangeEvent) => {
    if (event.target.value !== settings.brand) {
      setSettings({
        locale: settings.locale,
        brand: event.target.value as BrandKey,
        initialView: settings.initialView,
      });
    }
  };

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
  };

  const getLanguageOptions = () => {
    return locales.map((lang) => {
      return (
        <MenuItem key={lang} value={lang}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            {t(`languages.${lang}`)}
            <img
              src={getFlag(lang)}
              alt={t('flags.' + lang)}
              style={{
                width: '1em',
                height: '1em',
                marginLeft: '0.4rem',
                verticalAlign: 'text-top',
              }}
            />
          </Box>
        </MenuItem>
      );
    });
  };

  const getBrandOptions = () => {
    return getSupportedBrands(settings.initialView)
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((brand) => {
        return (
          <MenuItem key={brand.key} value={brand.key}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              {brand.label}
              <img
                src={brand.logo}
                alt={`Logo of ${brand.label}`}
                style={{
                  height: '1em',
                  marginLeft: '0.4rem',
                  verticalAlign: 'text-top',
                }}
              />
            </Box>
          </MenuItem>
        );
      });
  };

  const detailsContent: { [key: string]: JSX.Element } = {
    settings: (
      <>
        <StyledTitle>{t('agentProfile.settings.title')}</StyledTitle>
        <FormGroup
          sx={{
            '& > *': { margin: 2 },
          }}
        >
          <FormControl key="locale" margin="normal">
            <InputLabel variant="standard">
              {t('agentProfile.settings.language.title')}
            </InputLabel>
            <Select
              variant="standard"
              name="locale"
              value={settings.locale}
              onChange={handleLanguageChange}
            >
              {getLanguageOptions()}
            </Select>
            <FormHelperText>
              {t('agentProfile.settings.language.description')}
            </FormHelperText>
          </FormControl>

          <FormControl key="initialView" margin="normal">
            <InputLabel variant="standard">
              {t('agentProfile.settings.initialView.title')}
            </InputLabel>
            <Select
              variant="standard"
              name="initialView"
              value={settings.initialView}
              disabled={!isAuthorized || !isLoyaltyAuthorized}
              onChange={handleInitialViewChange}
            >
              {isAuthorized && (
                <MenuItem value={AppSection.OrderSearch}>
                  {t('agentProfile.settings.initialView.values.OrderSearch')}
                </MenuItem>
              )}
              {isLoyaltyAuthorized && (
                <MenuItem value={AppSection.Loyalty}>
                  {t('agentProfile.settings.initialView.values.Loyalty')}
                </MenuItem>
              )}
            </Select>
            <FormHelperText>
              {t('agentProfile.settings.initialView.description')}
            </FormHelperText>
          </FormControl>

          <FormControl key="brand" margin="normal">
            <InputLabel variant="standard">
              {t('agentProfile.settings.brand.title')}
            </InputLabel>
            <Select
              variant="standard"
              name="brand"
              value={settings.brand}
              onChange={handleBrandChange}
            >
              {getBrandOptions()}
            </Select>
            <FormHelperText>
              {t('agentProfile.settings.brand.description')}
            </FormHelperText>
          </FormControl>
        </FormGroup>
      </>
    ),
    details: (
      <>
        <StyledTitle>{t('agentProfile.details')}</StyledTitle>
        <DetailItem label={t('agentProfile.name')} value={agentInfo.name} />
        <DetailItem label={t('agentProfile.email')} value={agentInfo.email} />
        <DetailItem
          label={t('agentProfile.salaryId')}
          value={agentInfo.salaryId}
        />
      </>
    ),
    roles: (
      <>
        <StyledTitle>{t('agentProfile.salaryId')}</StyledTitle>
        {agentInfo.salaryId || t('generic.notAvailable')}
        <StyledTitle>{t('agentProfile.roles')}</StyledTitle>
        {agentInfo.roles.length > 0 ? (
          agentInfo.roles.map((role, index) => (
            <StyledDescription key={index}>{role}</StyledDescription>
          ))
        ) : (
          <StyledDescription>{t('agentProfile.noRoles')}</StyledDescription>
        )}
      </>
    ),
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={3} sx={{ padding: 2, height: '100%' }}>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={2}
            sx={{
              padding: 2,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Avatar
                alt={agentInfo.name || 'User'}
                src={''}
                sx={{ width: 80, height: 80 }}
              />
              <StyledTitle gutterBottom>{agentInfo.name}</StyledTitle>
              <StyledDescription
                gutterBottom
                sx={{ wordWrap: 'break-word', width: '100%' }}
              >
                {agentInfo.email}
              </StyledDescription>
            </Box>

            <List>
              <ListItemWithIcon
                icon={<Tune />}
                secondary={t('agentProfile.settings.title')}
                onClick={() => handleItemClick('settings')}
              />
              <ListItemWithIcon
                icon={<AccountCircle />}
                secondary={t('agentProfile.details')}
                onClick={() => handleItemClick('details')}
              />
              <ListItemWithIcon
                icon={<Security />}
                secondary={t('agentProfile.roles')}
                onClick={() => handleItemClick('roles')}
              />
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
            {detailsContent[selectedItem]}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfilePage;
