import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { IGetOrderDetailsParams, IOrderDetailsResult } from '@model/order';
import { useParams } from 'react-router-dom';
import { OrderService } from '@/service/OrderService';
import { addressFormatter } from '@utils/addressFormatter';
import Stack from '@mui/material/Stack';
import { trackPromise } from 'react-promise-tracker';
import { enqueueSnackbar } from 'notistack';
import appState from '@/state';
import { t } from 'i18next';
import CustomerAddress from '@components/order-details-view/product-details/CustomerAddress';
import CustomerRemarksFAB from './CustomerRemarksFAB';
import OrderDetailsHeader from '@components/order-search-view/order-details/OrderDetailsHeader';
import CustomerDetails from '@components/order-details-view/CustomerDetails';
import Loader from '@components/mui-helpers/Loader';
import { ICustomTab } from '@components/mui-helpers/ICustomTab';
import CustomTabPanel from '@components/mui-helpers/CustomTabPanel';
import ProductList from '@/components/order-details-view/product-details/ProductList';

const OrderDetailsTab: React.FC = () => {
  const customerDetails = appState.customer.details.value;
  const [selectedOrderDetails, setSelectedOrderDetails] =
    useState<IOrderDetailsResult>();
  let { orderId, brand } = useParams();
  const orderService: OrderService = new OrderService();

  useEffect(() => {
    const getOrderDetails = async () => {
      try {
        const orderDetails = await orderService.getFullOrderDetails({
          orderId: orderId,
          brand: brand,
        } as IGetOrderDetailsParams);

        if (orderDetails) {
          appState.customer.address.value = addressFormatter(
            orderDetails.shippingAddress,
          );
        }
        setSelectedOrderDetails(orderDetails);
      } catch (err) {
        enqueueSnackbar(`Ingen detaljer fundet for ordre-id: ${orderId}`);
        console.error('Error fetching order details', err);
        window.close();
      }
    };
    trackPromise(getOrderDetails(), 'order-details-area');
  }, [orderId, brand]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabs: ICustomTab[] = [
    {
      id: 'productOverviewTab',
      name: t('orderDetailsTabs.products'),
      content: (
        <Box key="productOverview">
          <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            sx={{ gap: '2rem' }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              {selectedOrderDetails && (
                <ProductList products={selectedOrderDetails.products} />
              )}
            </Box>
            <Box>
              {selectedOrderDetails && (
                <CustomerAddress
                  shippingAddress={selectedOrderDetails.shippingAddress}
                  billingAddress={selectedOrderDetails.billingAddress}
                />
              )}
            </Box>
          </Stack>
        </Box>
      ),
      failedRequests: false,
    },
    {
      id: 'customerDetailsTab',
      name: t('orderDetailsTabs.customerProfile'),
      content: (
        <Box key="CustomerDetails" p={3}>
          {customerDetails && <CustomerDetails />}
        </Box>
      ),
      failedRequests: false,
      disabled: appState.customer.details.value === undefined,
    },
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box p={3} sx={{ width: '100%', maxWidth: '1440px' }}>
        <Loader area="order-details-area" />
        {selectedOrderDetails ? (
          <>
            <OrderDetailsHeader order={selectedOrderDetails} />

            <CustomerRemarksFAB
              order={selectedOrderDetails}
              brand={brand || ''}
            />
          </>
        ) : (
          <></>
        )}
        <CustomTabPanel tabs={tabs} />
      </Box>
    </Box>
  );
};

export default OrderDetailsTab;
