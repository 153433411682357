import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IProduct } from '@/model/order';
import theme from '@/theme';
import ProductTracking from './ProductTracking';
import CustomImage from '@components/mui-helpers/CustomImage';
import ProductHistory from './ProductHistory';
import { useTranslation } from 'react-i18next';
import ProductTable from './ProductTable';
import ProductPrice from './ProductPrice';

interface ProductDialogProps {
  selectedProduct: IProduct | null;
  handleCloseDialog: () => void;
}

const ProductDialog: React.FC<ProductDialogProps> = ({
  selectedProduct,
  handleCloseDialog,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={Boolean(selectedProduct)}
      onClose={handleCloseDialog}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {selectedProduct && (
        <>
          <DialogTitle
            id="dialog-title"
            sx={{ color: theme.palette.primary.main }}
          >
            {selectedProduct.name}
          </DialogTitle>
          <DialogContent id="dialog-description">
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Box sx={{ background: '#f5f5f5' }}>
                <CustomImage height={200} title="product image" />
              </Box>
              <Box sx={{ flex: 1 }}>
                <ProductTable product={selectedProduct} />
              </Box>
            </Stack>
            <ProductPrice product={selectedProduct} />
            <ProductTracking tracking={selectedProduct.tracking} />
            <ProductHistory
              history={selectedProduct.history}
              productId={selectedProduct.productId}
            />
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button
          autoFocus
          aria-label="Close product details dialog"
          onClick={handleCloseDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductDialog;
