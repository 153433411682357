import React, { useCallback, useEffect, useState } from 'react';
import { IDiscount, ILinkedArticle, IReceiptOrderResult } from '@model/receipt';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List/List';
import Box from '@mui/material/Box/Box';
import { currencyFormatter } from '@utils/priceFormatter';
import ListItemText from '@mui/material/ListItemText';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import { formatDate } from '@utils/date-utils/formatDate';
import NoContentMessage from '../../mui-helpers/NoContentMessage';
import {
  StyledDescription,
  StyledTitle,
  StyledTypography,
} from '../../mui-helpers/customComponentStyles';
import Chip from '@mui/material/Chip';
import green from '@mui/material/colors/green';
import ReplyIcon from '@mui/icons-material/Reply';
import Tooltip from '@mui/material/Tooltip';
import { fetchReceiptById } from '@/service/ReceiptService';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { addressFormatter } from '@utils/addressFormatter';
import { emptyValueReplacement } from '@utils/emptyValueReplacement';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from '@utils/copyToClipboard';
import ReceiptItemSkeleton from './ReceiptItemSkeleton';
import { enqueueSnackbar } from 'notistack';
import appState from '@/state';
import ReceiptPayment from './ReceiptPayment';
import HoverListItem from '@components/mui-helpers/HoverListItem';

interface IReceiptItemProps {
  receiptId: string;
}

const ReceiptItem: React.FC<IReceiptItemProps> = ({ receiptId }) => {
  const customerBrand = appState.customer.brand.value;
  const customerDetails = appState.customer.details.value;

  const [receiptDetails, setReceiptDetails] =
    useState<IReceiptOrderResult | null>(null);
  const [currency, setCurrency] = useState<string>('');
  const [cachedReceipts, setCachedReceipts] = useState<{
    [key: string]: IReceiptOrderResult;
  }>({});

  const { t } = useTranslation();
  const receiptDetailsTracker = usePromiseTracker({
    area: 'receipt-item-area',
  });

  const getReceiptItem = useCallback(async () => {
    if (!customerDetails || !customerBrand) {
      console.warn('Missing customerUuid or customerBrand');
      enqueueSnackbar(t('messages.searchCustomerFirst'));
      return;
    }

    if (cachedReceipts[receiptId]) {
      setReceiptDetails(cachedReceipts[receiptId]);
      setCurrency(cachedReceipts[receiptId].currency);
    } else {
      try {
        const receiptItem = await fetchReceiptById(
          customerDetails.uid,
          customerBrand,
          receiptId,
        );
        setReceiptDetails(receiptItem);
        setCurrency(receiptItem.currency);
        setCachedReceipts((prevState) => ({
          ...prevState,
          [receiptId]: receiptItem,
        }));
      } catch (err) {
        console.error('Error fetching receipt item:', err);
        enqueueSnackbar(t('receipt.noReceiptDetails'));
      }
    }
  }, [
    receiptId,
    customerDetails,
    customerBrand,
    cachedReceipts,
    setReceiptDetails,
    setCurrency,
    setCachedReceipts,
    t,
  ]);

  useEffect(() => {
    trackPromise(getReceiptItem(), 'receipt-item-area');
  }, [getReceiptItem, t]);

  if (!receiptDetails) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100%',
          textAlign: 'center',
        }}
      >
        <NoContentMessage infoMessage={t('receipt.noReceiptDetails')} />
      </Box>
    );
  }

  const renderLinkedArticle = (article?: ILinkedArticle) => {
    return article ? (
      <Box style={{ width: '100%' }}>
        <HoverListItem
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: 0,
          }}
        >
          <StyledTypography color="text.secondary">
            {emptyValueReplacement(article.name)}
            {article.isRefundable && (
              <Tooltip title={t('receipt.refundable')}>
                <Chip
                  icon={<ReplyIcon />}
                  color="primary"
                  size="small"
                  sx={{
                    ml: 1,
                    backgroundColor: green[500],
                    color: 'white',
                    '& .MuiChip-icon': {
                      marginLeft: '0.6rem',
                    },
                    height: 'auto',
                    alignItems: 'center',
                    lineHeight: 'inherit',
                  }}
                />
              </Tooltip>
            )}
          </StyledTypography>
          <Typography sx={{ textAlign: 'right', marginRight: '0' }}>
            {currencyFormatter(article.totalPrice, currency)}
          </Typography>
        </HoverListItem>
        {renderLinkedArticle(article.linkedArticle)}
      </Box>
    ) : null;
  };

  if (!receiptDetails.orderLines) {
    return (
      <Typography>
        {t('receipt.orderLine')}:{' '}
        {emptyValueReplacement(receiptDetails.orderLines)}
      </Typography>
    );
  }

  const renderDiscounts = (discounts?: IDiscount[]) => (
    <Box style={{ width: '100%' }}>
      {discounts?.map((discount, index) => (
        <HoverListItem
          key={`${index}-${discount.name}`}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: 0,
          }}
        >
          <Tooltip
            title={
              discount.offerId
                ? t('receipt.offerIdTooltip')
                : t('receipt.noOfferId')
            }
          >
            <StyledTypography color="text.secondary">
              {discount.name}
              <span
                style={{
                  cursor: 'pointer',
                  fontSize: '0.8rem',
                  marginLeft: '0.2rem',
                  display: 'inline-block',
                  alignSelf: 'center',
                }}
                onClick={() =>
                  discount.offerId &&
                  copyToClipboard(discount.offerId.toString())
                }
              >
                {emptyValueReplacement(discount.offerId)}
              </span>
            </StyledTypography>
          </Tooltip>
          <Typography sx={{ textAlign: 'right', marginRight: '0' }}>
            {currencyFormatter(discount.totalPrice, currency)}
          </Typography>
        </HoverListItem>
      ))}
    </Box>
  );

  return (
    <Box sx={{ px: 3 }}>
      {receiptDetailsTracker.promiseInProgress ? (
        <ReceiptItemSkeleton area="receipt-item-area" />
      ) : (
        <Card elevation={3}>
          <CardContent>
            <StyledTitle sx={{ textAlign: 'center' }}>
              {formatDate(receiptDetails.orderDate)}
            </StyledTitle>
            <StyledDescription sx={{ textAlign: 'center' }}>
              {emptyValueReplacement(receiptDetails.barcode?.displayValue)}
            </StyledDescription>
            <StyledDescription sx={{ textAlign: 'center' }}>
              {emptyValueReplacement(receiptDetails.store.name)}
            </StyledDescription>
            <StyledDescription sx={{ textAlign: 'center' }}>
              {addressFormatter(receiptDetails.store)}
            </StyledDescription>
            <Divider key={`receipt-divider-${receiptId}`} sx={{ my: 2 }} />
            <List sx={{ textTransform: 'uppercase' }}>
              {receiptDetails.orderLines.map((line, index) => (
                <Box
                  key={`${index}- ${line.articleID}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <HoverListItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: 0,
                      cursor: 'default',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemText
                        primary={emptyValueReplacement(line.name)}
                      />

                      <Tooltip
                        title={
                          line.articleID
                            ? t('productHeaders.copyArticleId')
                            : t('productHeaders.noArticleId')
                        }
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            cursor: 'pointer',
                            display: 'block',
                            alignSelf: 'center',
                            margin: '0 0.5rem',
                            marginY: 'auto',
                          }}
                          onClick={() =>
                            line.articleID &&
                            copyToClipboard(line.articleID.toString())
                          }
                        >
                          {emptyValueReplacement(line.articleID)}
                        </Typography>
                      </Tooltip>

                      {line.isRefundable && (
                        <Tooltip title={t('receipt.refundable')}>
                          <Chip
                            icon={<ReplyIcon />}
                            color="primary"
                            size="small"
                            sx={{
                              backgroundColor: green[500],
                              color: 'white',
                              '& .MuiChip-icon': {
                                marginLeft: '0.6rem',
                              },
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Typography sx={{ textAlign: 'right' }}>
                      {currencyFormatter(line.totalPrice, currency)}
                    </Typography>
                  </HoverListItem>
                  {renderDiscounts(line.discounts ?? undefined)}
                  {renderLinkedArticle(line.linkedArticle ?? undefined)}
                </Box>
              ))}
            </List>
            <Divider sx={{ my: 2 }} />
            <ReceiptPayment payments={receiptDetails.payments ?? []} />
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default ReceiptItem;
