import { IGetNShiftShipmentsParams, IShipment } from '@model/nshift';
import axios from 'axios';
import { appConfig } from '@/appConfig';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';

const fetchNShiftShipments = async (
  params: IGetNShiftShipmentsParams,
): Promise<IShipment[]> => {
  let url = `${appConfig.sgApiNshiftShipments}${params.query}`;
  if (params.startDate && params.endDate) {
    url += `?startDate=${params.startDate}&endDate=${params.endDate}`;
  }

  const response = await axios({
    method: 'GET',
    url: url,
    headers: {
      Authorization: `Bearer ${appConfig.sgBearer}`,
      'Content-Type': 'application/json',
    },
    timeout: 5000,
  });

  return response.data;
};

export const useNShiftShipments = (
  params: IGetNShiftShipmentsParams | undefined,
) => {
  const { t } = useTranslation();

  const nShiftQuery = useQuery<IShipment[]>({
    queryKey: ['nShiftShipments', params],
    queryFn: () => fetchNShiftShipments(params as IGetNShiftShipmentsParams),
    enabled: false,
  });

  useEffect(() => {
    if (nShiftQuery.isSuccess) {
      if (!nShiftQuery.data) {
        enqueueSnackbar(t('errors.noDataForTrackingId'));
      }
    }

    if (nShiftQuery.isError || nShiftQuery.isRefetchError) {
      enqueueSnackbar(t('errors.trackingFetchError'), { variant: 'error' });
      console.error('Error fetching tracking details', nShiftQuery.error);
    }
  }, [nShiftQuery.dataUpdatedAt, nShiftQuery.errorUpdatedAt]); // eslint-disable-line react-hooks/exhaustive-deps

  return nShiftQuery;
};
