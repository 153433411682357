import React from 'react';
import { IProductHistory } from '@/model/order';
import CaptionedText from '@components/mui-helpers/CaptionedText';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';
import { priceFormatter } from '@/utils/priceFormatter';
import { useTranslation } from 'react-i18next';

interface ProductHistoryItemProps {
  historyItem: IProductHistory;
}

const ProductHistoryItem: React.FC<ProductHistoryItemProps> = ({
  historyItem,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CaptionedText
        caption={t('orderDetails.product.status')}
        text={historyItem.status}
      />
      <CaptionedText
        caption={t('orderDetails.product.invoiceDate')}
        text={emptyValueReplacement(historyItem.invoiceDate)}
      />
      <CaptionedText
        caption={t('orderDetails.product.consignment')}
        text={emptyValueReplacement(historyItem.consignmentNumber)}
      />
      <CaptionedText
        caption={t('orderDetails.history.expectedDeliveryDate')}
        text={emptyValueReplacement(historyItem.expectedDeliveryDate)}
      />
      <CaptionedText
        caption={t('orderDetails.product.quantity')}
        text={historyItem.quantity}
      />
      <CaptionedText
        caption={t('orderDetails.product.totalDiscount')}
        text={
          historyItem.totalDiscount != null
            ? priceFormatter(historyItem.totalDiscount)
            : 'N/A'
        }
      />
      <CaptionedText
        caption={t('orderDetails.product.totalPrice')}
        text={
          historyItem.totalPrice != null
            ? priceFormatter(historyItem.totalPrice)
            : t('orderDetails.history.na')
        }
      />
    </>
  );
};

export default ProductHistoryItem;
