import { GridColDef } from '@mui/x-data-grid';

export const stockColumns: GridColDef[] = [
  {
    field: 'siteId',
    headerName: 'Butik No.',
    minWidth: 200,
    sortable: true,
    flex: 2,
  },
  {
    field: 'currentStock',
    headerName: 'Nuværende Lager',
    minWidth: 130,
    sortable: true,
    flex: 1,
  },
];
